.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}
.container {
  width: 1400px;
  margin: auto;
}
@media screen and (max-width: 1600px) {
  .container {
    width: 72%;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 86%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    width: 92%;
  }
}
.layout-container {
  padding-top: 80px;
  min-height: 600px;
  max-width: 1920px;
  margin: auto;
  background: #fff;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .layout-container {
    padding-top: 60px;
  }
}
.database-margin {
  margin-top: -60px;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/openSans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('../../assets/openSans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../../assets/openSans/OpenSans-Light.ttf') format('truetype');
}
.float-frame-wrap {
  position: fixed;
  bottom: 50px;
  left: 50%;
  padding: 32px 40px;
  z-index: 97;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 8px;
}
.float-frame-wrap .agreement-content {
  width: 100%;
  font-size: 16px;
  color: #333333;
  line-height: 24px;
}
.float-frame-wrap .agreement-content > a,
.float-frame-wrap .agreement-content span {
  margin-left: 8px;
  font-size: 16px;
  color: #E70113;
  line-height: 24px;
  text-decoration: underline;
}
