.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}
.container {
  width: 1400px;
  margin: auto;
}
@media screen and (max-width: 1600px) {
  .container {
    width: 72%;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 86%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    width: 92%;
  }
}
.layout-container {
  padding-top: 80px;
  min-height: 600px;
  max-width: 1920px;
  margin: auto;
  background: #fff;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .layout-container {
    padding-top: 60px;
  }
}
.database-margin {
  margin-top: -60px;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/openSans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('../../assets/openSans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../../assets/openSans/OpenSans-Light.ttf') format('truetype');
}
.footer-wrap {
  background-color: #222222;
}
.footer-wrap .footer-menu {
  display: none;
}
.footer-wrap .ant-menu {
  background-color: #222222;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-wrap .footer-content {
  display: flex;
  justify-content: space-between;
  padding-top: 56px;
  min-height: 344px;
}
.footer-wrap .footer-content .footer-nav {
  width: calc(100% - 320px);
  display: flex;
}
.footer-wrap .footer-content .footer-nav > li {
  margin-right: 20px;
  max-width: 224px;
}
.footer-wrap .footer-content .footer-nav > li .nav-name {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 22px;
}
.footer-wrap .footer-content .footer-nav > li .nav-item {
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 19px;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.footer-wrap .footer-content .footer-nav > li .nav-item:hover {
  color: #E70113;
  text-decoration: underline;
}
.footer-wrap .footer-content .content-right {
  width: 320px;
}
.footer-wrap .footer-content .content-right .news-title {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 22px;
}
.footer-wrap .footer-content .content-right .email-box {
  max-width: 280px;
  margin: 24px 0 56px;
  display: flex;
}
.footer-wrap .footer-content .content-right .email-box .icon-btn {
  cursor: pointer;
  width: 46px;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  background-color: #E70113;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  vertical-align: bottom;
  border: none;
  padding: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.footer-wrap .footer-content .content-right .email-box .icon-btn > i.iconfont {
  font-size: 20px;
}
.footer-wrap .footer-content .content-right .ant-form-item {
  margin-bottom: 0;
  width: 234px;
}
.footer-wrap .footer-content .content-right .ant-input {
  max-width: 234px;
  padding: 10px 14px;
  height: 38px;
  font-size: 14px;
  border-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
}
.footer-wrap .footer-content .content-right .ant-input::placeholder {
  color: #C1C1C1;
}
.footer-wrap .footer-content .content-right .ant-form-item-explain.ant-form-item-explain-error {
  color: #E70113;
}
.footer-wrap .footer-content .content-right .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: #E70113;
}
.footer-wrap .footer-content .content-right .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  border-color: #E70113;
}
.footer-wrap .footer-content .content-right .media-list {
  display: flex;
}
.footer-wrap .footer-content .content-right .media-list > li {
  width: 28px;
  height: 28px;
  margin-right: 24px;
}
.footer-wrap .footer-content .content-right .media-list > li .media-link {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.48);
  text-align: center;
  cursor: pointer;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.footer-wrap .footer-content .content-right .media-list > li .media-link > .iconfont {
  font-size: 16px;
  line-height: 28px;
  color: #222;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.footer-wrap .footer-content .content-right .media-list > li .media-link:hover {
  background-color: #E70113;
}
.footer-wrap .footer-content .content-right .media-list > li .media-link:hover > .iconfont {
  color: #fff;
}
.footer-wrap .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-wrap .footer-bottom .bottom-content-pc {
  display: flex;
  justify-content: space-between;
  padding: 23px 0;
}
.footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content {
  display: flex;
  color: #7a7a7a;
}
.footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content .beian1 {
  color: #7a7a7a;
  margin-left: 5px;
  margin-right: 5px;
}
.footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content .beian1:hover {
  color: #E70113;
}
.footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content .beian2 {
  color: #7a7a7a;
  margin-left: 5px;
}
.footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content .beian2:hover {
  color: #E70113;
}
.footer-wrap .footer-bottom .bottom-content-pc .privacy-link {
  color: #7a7a7a;
  cursor: pointer;
}
.footer-wrap .footer-bottom .bottom-content-pc .privacy-link:hover {
  color: #E70113;
}
.footer-wrap .footer-bottom .bottom-content-pc > p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  line-height: 19px;
  display: flex;
}
.footer-wrap .footer-bottom .bottom-content-pc > a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  line-height: 19px;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.footer-wrap .footer-bottom .bottom-content-pc > a:hover {
  color: #E70113;
}
.footer-wrap .footer-bottom .bottom-content {
  display: none;
}
@media screen and (max-width: 996px) {
  .footer-wrap .footer-menu {
    display: block;
  }
  .footer-wrap .footer-content {
    min-height: auto;
    padding-bottom: 30px;
    padding-top: 24px;
  }
  .footer-wrap .footer-content .footer-nav {
    display: none;
  }
  .footer-wrap .footer-content .content-right {
    width: 100%;
  }
  .footer-wrap .footer-content .content-right .email-box {
    max-width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .footer-wrap .footer-content .content-right .email-box .ant-form-item {
    width: 100%;
  }
  .footer-wrap .footer-content .content-right .email-box .ant-input {
    max-width: 100%;
  }
  .footer-wrap .footer-content .content-right .email-box .icon-btn {
    width: 60px;
  }
  .footer-wrap .footer-bottom .bottom-content-pc {
    flex-direction: column;
  }
  .footer-wrap .footer-bottom .bottom-content-pc .privacy-link {
    display: none;
  }
  .footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content {
    flex-direction: column;
  }
  .footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content .beian1 {
    color: #7a7a7a;
    margin-left: 0;
  }
  .footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content .beian1:hover {
    color: #E70113;
  }
  .footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content .beian2 {
    color: #7a7a7a;
    margin-left: 0;
  }
  .footer-wrap .footer-bottom .bottom-content-pc .bottom-left-content .beian2:hover {
    color: #E70113;
  }
  .footer-wrap .footer-bottom .bottom-content-pc > p {
    display: block;
  }
  .footer-wrap .footer-bottom .bottom-content-pc > p > div {
    display: flex;
    justify-content: center;
  }
  .footer-wrap .footer-bottom .bottom-content-pc img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  .footer-wrap .footer-bottom .bottom-content-pc > a {
    margin-top: 16px;
  }
  .footer-wrap .footer-bottom .bottom-content {
    display: block;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    color: #7a7a7a;
    padding: 24px 0;
    cursor: pointer;
  }
}
