.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}
.container {
  width: 1400px;
  margin: auto;
}
@media screen and (max-width: 1600px) {
  .container {
    width: 72%;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 86%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    width: 92%;
  }
}
.layout-container {
  padding-top: 80px;
  min-height: 600px;
  max-width: 1920px;
  margin: auto;
  background: #fff;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .layout-container {
    padding-top: 60px;
  }
}
.database-margin {
  margin-top: -60px;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/openSans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('../../assets/openSans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../../assets/openSans/OpenSans-Light.ttf') format('truetype');
}
.header-wrap {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  border-top: none;
  background: linear-gradient(rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0));
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-wrap .pc-header {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 4%;
}
.header-wrap .pc-header .pc-header-logo {
  max-width: 154px;
}
.header-wrap .pc-header .pc-header-logo a {
  display: inline-block;
  width: 100%;
}
.header-wrap .pc-header .pc-header-logo a img {
  width: 100%;
}
.header-wrap .pc-header .pc-header-nav {
  display: flex;
  align-items: center;
}
.header-wrap .pc-header .pc-header-nav .nav-item {
  padding-left: 28px;
  padding-right: 28px;
  border-bottom: 2px solid transparent;
  position: relative;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-wrap .pc-header .pc-header-nav .nav-item .item-link {
  display: inline-block;
  height: 100%;
  line-height: 70px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.header-wrap .pc-header .pc-header-nav .nav-item .item-link > .iconfont {
  font-size: 18px;
  margin-left: 8px;
  vertical-align: middle;
}
.header-wrap .pc-header .pc-header-nav .nav-item .item-link:last-child:hover {
  color: #E70113;
}
.header-wrap .pc-header .pc-header-nav .nav-item:last-child {
  margin-right: 0;
}
.header-wrap .pc-header .pc-header-nav .nav-item:hover {
  border-color: #E70113;
}
.header-wrap .pc-header .pc-header-right {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 10%;
}
.header-wrap .pc-header .pc-header-right .lang-box {
  height: 100%;
  display: flex;
  align-items: center;
}
.header-wrap .pc-header .pc-header-right .lang-box .ant-select-selection-item {
  color: #fff !important;
  padding-right: 27px;
}
.header-wrap .pc-header .pc-header-right .lang-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0px;
}
.header-wrap .pc-header .pc-header-right .lang-box .ant-select-arrow {
  color: #fff;
}
.header-wrap .pc-header .pc-header-right .lang-box > .iconfont {
  font-size: 18px;
  color: #fff;
  margin-right: 4px;
}
.header-wrap .pc-header .pc-header-right .lang-box .split-line::after {
  content: "/";
  margin-left: 4px;
}
.header-wrap .pc-header .pc-header-right .lang-box > span {
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
  line-height: 19px;
}
.header-wrap .pc-header .pc-header-right .lang-box > span a {
  color: #ffffff;
}
.header-wrap .pc-header .pc-header-right .lang-box > span:nth-child(3) {
  margin: 0 5px;
}
.header-wrap .pc-header .pc-header-right .shop-store {
  display: inline-block;
  margin-left: 21px;
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  height: 36px;
}
.header-wrap .pc-header .pc-header-right .shop-store > .iconfont {
  font-size: 16px;
  color: #fff;
  vertical-align: middle;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-wrap .pc-header .pc-header-right .shop-store > span {
  margin-left: 6px;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  vertical-align: middle;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-wrap .pc-header .pc-header-right .shop-store > .name {
  color: #ffffff;
}
.header-wrap .pc-header .pc-header-right .shop-store:hover {
  border: 1px solid #E70113;
  background-color: #E70113;
}
.header-wrap .pc-header .pc-header-right .shop-store:hover > .iconfont,
.header-wrap .pc-header .pc-header-right .shop-store:hover > span {
  color: #fff;
}
.header-wrap .pc-header .pc-header-right .newsletter-box {
  border-radius: 4px;
  margin-left: 8px;
  color: #fff;
  padding: 8px 12px;
  height: 36px;
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
}
.header-wrap .pc-header .pc-header-right .newsletter-box:hover {
  border: 1px solid #E70113;
  background-color: #E70113;
}
.header-wrap .pc-header .pc-header-right .newsletter-box:hover > .iconfont,
.header-wrap .pc-header .pc-header-right .newsletter-box:hover > .name {
  color: #fff;
}
.header-wrap .pc-header .pc-header-right .newsletter-box > .iconfont {
  font-size: 16px;
  margin-right: 6px;
}
.header-wrap .pc-header .pc-header-right .newsletter-box .name {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
.header-wrap .pc-header .custom-badge-cls .ant-badge-count {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 22px;
  text-align: center;
  transform: translate(0, 0);
  padding: 0;
  border: 1px solid #fff;
  right: -3px !important;
  top: -9px !important;
}
.header-wrap .pc-header .more-select {
  display: none;
  width: max-content;
  position: absolute;
  top: 72px;
  left: -40px;
  z-index: 99;
  background-color: #fff;
}
.header-wrap .pc-header .more-select .select-nav {
  padding: 16px 0;
}
.header-wrap .pc-header .more-select .select-nav > li .select-nav-link {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 12px 40px;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-wrap .pc-header .more-select .select-nav > li:hover .select-nav-link {
  background-color: #f4f4f4;
  color: #E70113;
}
.header-wrap .pc-header .product-select {
  display: none;
  width: 100%;
  height: 412px !important;
  position: fixed;
  top: 72px;
  left: 0;
  z-index: 999;
  background-color: #fff;
}
.header-wrap .pc-header .product-select .prouct-select-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav {
  flex: 2 1;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  border-right: 1px solid #e8e8e8;
  background: #f8f8f8;
  max-width: 66vw;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs {
  height: 100%;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs .ant-tabs-nav-wrap {
  width: 100%;
  height: 54px;
  background: #f8f8f8;
  padding-top: 16px;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background-color: #ffffff;
  color: #f42000;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab:hover {
  background-color: #ffffff;
  color: #f42000;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab {
  width: 100%;
  height: 40px;
  background: #f8f8f8;
  margin: 0;
  padding: 0;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn {
  width: 100%;
  height: 100%;
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  line-height: 40px;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn div {
  width: 100%;
  height: 100%;
  padding: 0 32px;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab-btn:focus,
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab-btn:active {
  color: #333;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs-content {
  height: 100%;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs-ink-bar {
  left: 0;
  background: #f42000;
  height: 16px;
  width: 4px;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .ant-tabs-tabpane {
  padding: 0;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .navTabs .ant-tabs-nav {
  background: #fff;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .navTabs .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #fff;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .navTabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab {
  background: #fff;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .navTabs .ant-tabs-ink-bar {
  left: 0;
  background: #fff;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .navTabs .list_nav_name {
  font-size: 16px;
  padding: 16px 32px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #d8d8d8 #fff;
  height: 100%;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .navTabs .list_nav_name .list_nav_tite {
  cursor: pointer;
  line-height: 40px;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .navTabs .list_nav_name .list_nav_tite:hover {
  color: #f42000;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .nav-list {
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  top: 0;
  right: 0;
  padding: 0 24px;
  padding-bottom: 48px;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .nav-list .list-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin-bottom: 20px;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .nav-list .list-title > .iconfont {
  font-size: 16px;
  margin-left: 8px;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .nav-list .list-title:hover {
  color: #E70113;
  cursor: pointer;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .nav-list .list-title .fold-arrow {
  display: inline-block;
  margin-left: 5px;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .nav-list .list-detail {
  display: block;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  margin-bottom: 16px;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .nav-list .list-detail:last-child {
  margin-bottom: 0;
}
.header-wrap .pc-header .product-select .prouct-select-content .left-nav .nav-list .list-detail:hover {
  color: #E70113;
  text-decoration: underline;
  cursor: pointer;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail {
  flex: 0.7 1;
  height: 100%;
  padding: 32px;
  padding-bottom: 0;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #d8d8d8 #e8e8e8;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail:hover {
  cursor: pointer;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .img-box {
  width: 100%;
  height: 200px;
  background: #f8f8f8;
  margin-bottom: 24px;
  border-color: transparent !important;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .img-box > img {
  display: block;
  width: 240px;
  height: 100%;
  margin: 0 auto;
  border-color: transparent !important;
  object-fit: contain;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .solution-img-box {
  background-color: #fff;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .solution-img-box > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .detail {
  width: 100%;
  max-height: 530px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .detail .detail-title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .detail .sub-title {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  margin: 8px 0 16px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .detail .label-box {
  text-align: center;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .detail .label-box > span {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 5px 12px;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
}
.header-wrap .pc-header .product-select .prouct-select-content .right-detail .detail .label-box > span:last-child {
  margin-right: 0;
}
@media screen and (max-width: 1500px) {
  .header-wrap .pc-header .product-select .prouct-select-content .right-detail {
    padding: 32px 16px;
    padding-bottom: 0;
  }
}
.header-wrap .pc-header .solution-select {
  display: none;
  width: 100%;
  height: 446px !important;
  position: fixed;
  top: 72px;
  left: 0;
  z-index: 999;
  background-color: #fff;
}
.header-wrap .pc-header .solution-select .prouct-select-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav {
  padding-top: 48px;
  flex: 2 1;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  border-right: 1px solid #e8e8e8;
  background: #fff;
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-box {
  position: relative;
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-boxs {
  column-count: 2;
  -moz-column-count: 2;
  /* Firefox */
  -webkit-column-count: 2;
  /* Safari and Chrome */
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-list {
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  top: 0;
  right: 0;
  padding: 0 24px;
  padding-bottom: 48px;
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-list .list-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin-bottom: 20px;
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-list .list-title > .iconfont {
  font-size: 16px;
  margin-left: 8px;
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-list .list-title:hover {
  color: #E70113;
  cursor: pointer;
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-list .list-title .fold-arrow {
  display: inline-block;
  margin-left: 5px;
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-list .list-detail {
  display: block;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  margin-bottom: 16px;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-list .list-detail:last-child {
  margin-bottom: 0;
}
.header-wrap .pc-header .solution-select .prouct-select-content .left-nav .nav-list .list-detail:hover {
  color: #E70113;
  text-decoration: underline;
  cursor: pointer;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail {
  flex: 1 1;
  height: 100%;
  padding: 48px;
  padding-bottom: 0;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail:hover {
  cursor: pointer;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .img-box {
  width: 400px;
  height: 200px;
  background: #f8f8f8;
  margin-bottom: 24px;
  border-color: transparent !important;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .img-box > img {
  display: block;
  width: 240px;
  height: 100%;
  margin: 0 auto;
  border-color: transparent !important;
  object-fit: contain;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .solution-img-box {
  background-color: #fff;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .solution-img-box > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .detail {
  margin: 0 auto;
  width: 92%;
  max-height: 530px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .detail .detail-title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .detail .sub-title {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  margin: 8px 0 16px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .detail .label-box {
  text-align: center;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .detail .label-box > span {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 5px 12px;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
}
.header-wrap .pc-header .solution-select .prouct-select-content .right-detail .detail .label-box > span:last-child {
  margin-right: 0;
}
.header-wrap .pc-header .solution-select-height {
  height: 446px;
}
.header-wrap .pc-header .select-show {
  display: block;
}
.header-wrap .mask-box {
  position: fixed;
  top: 71px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.header-wrap .mask-boxs {
  position: fixed;
  top: 72px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.header-wrap .mobile-header {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.header-wrap .mobile-header .mobile-header-logo {
  max-width: 154px;
}
.header-wrap .mobile-header .mobile-header-logo > a {
  display: inline-block;
  width: 100%;
}
.header-wrap .mobile-header .mobile-header-logo > a > img {
  width: 100%;
}
.header-wrap .mobile-header .mobile-header-section {
  display: flex;
  height: 100%;
}
.header-wrap .mobile-header .mobile-header-section .lang-box {
  width: 32px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.header-wrap .mobile-header .mobile-header-section .lang-box .lang-text {
  width: 32px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid #c8c8c8;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.header-wrap .mobile-header .mobile-header-section .nav-select {
  margin-left: 35px;
  width: 18px;
  height: 100%;
}
.header-wrap .mobile-header .mobile-header-section .nav-select .nav-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-wrap .mobile-header .mobile-header-section .nav-select .nav-icon > span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-wrap .mobile-header .mobile-header-section .nav-select .show-nav {
  justify-content: center;
}
.header-wrap .mobile-header .mobile-header-section .nav-select .show-nav > span:first-child {
  transform: rotate(45deg);
}
.header-wrap .mobile-header .mobile-header-section .nav-select .show-nav > span:nth-child(2) {
  transform: rotate(-45deg);
}
.header-wrap .mobile-header .mobile-header-section .nav-select .show-nav > span:last-child {
  display: none;
}
.header-wrap .nav-coloapse {
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  border-top: 1px solid #e8e8e8;
}
.header-wrap .nav-coloapse .mobile-nav {
  height: 55vh;
  overflow-y: auto !important;
  padding: 32px;
}
.header-wrap .nav-coloapse .mobile-nav > li {
  width: 100%;
  margin-bottom: 10px;
}
.header-wrap .nav-coloapse .mobile-nav > li .item-link {
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.header-wrap .nav-coloapse .mobile-nav > li .first-level {
  display: flex;
}
.header-wrap .nav-coloapse .mobile-nav > li .first-level span {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.header-wrap .nav-coloapse .mobile-nav > li .second-level {
  display: flex;
}
.header-wrap .nav-coloapse .mobile-nav > li .second-level span {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.header-wrap .nav-coloapse .mobile-nav > li .child-box {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: #555;
}
.header-wrap .nav-coloapse .mobile-nav > li .child-box:hover {
  color: #E70113;
}
.header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: #555;
}
.header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box span {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
}
.header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box:hover {
  color: #E70113;
}
.header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box .third-box {
  color: #555;
}
.header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box .third-box:hover {
  color: #E70113;
}
@media screen and (max-width: 1400px) {
  .header-wrap .pc-header .pc-header-nav .nav-item {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .left-nav {
    width: max-content;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .left-nav .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .left-nav .ant-tabs-tab .ant-tabs-tab-btn div {
    padding: 0 12px;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .left-nav .list_nav_name {
    font-size: 14px;
    padding: 16px;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail {
    height: 100%;
    padding: 2vw 0 0 2vw;
    width: 26vw;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail:hover {
    cursor: pointer;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .img-box {
    height: 150px;
    background: #f8f8f8;
    margin-bottom: 24px;
    border-color: transparent !important;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .img-box > img {
    display: block;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    border-color: transparent !important;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .solution-img-box {
    background-color: #fff;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .solution-img-box > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .detail {
    max-height: 530px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .detail .detail-title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .detail .sub-title {
    font-size: 14px;
    color: #333333;
    line-height: 22px;
    margin: 8px 0 16px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .detail .label-box {
    text-align: center;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .detail .label-box > span {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 5px 12px;
    border-radius: 4px;
    border: 1px solid #c1c1c1;
  }
  .header-wrap .pc-header .pc-header-nav .nav-item .product-select .prouct-select-content .right-detail .detail .label-box > span:last-child {
    margin-right: 0;
  }
  .header-wrap .pc-header .pc-header-right {
    margin-right: 5%;
  }
}
@media screen and (max-width: 1200px) {
  .header-wrap {
    background-color: #fff;
  }
  .header-wrap .pc-header {
    display: none;
  }
  .header-wrap .mobile-header {
    display: flex;
  }
  .header-wrap .nav-coloapse {
    display: block;
  }
  .header-wrap .nav-coloapse .mobile-nav > li {
    margin-bottom: 12px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .item-link {
    font-size: 20px;
    line-height: 24px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .first-level span {
    margin-right: 5px;
    font-size: 20px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .second-level span {
    margin-right: 5px;
    font-size: 20px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .child-box {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box {
    font-size: 16px;
    margin-bottom: 2px;
  }
}
@media screen and (max-width: 768px) {
  .header-wrap {
    background-color: #fff;
  }
  .header-wrap .pc-header {
    display: none;
  }
  .header-wrap .mobile-header {
    display: flex;
  }
  .header-wrap .nav-coloapse {
    display: block;
  }
  .header-wrap .nav-coloapse .mobile-nav > li {
    margin-bottom: 12px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .item-link {
    font-size: 20px;
    line-height: 24px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .first-level span {
    margin-right: 5px;
    font-size: 20px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .second-level span {
    margin-right: 5px;
    font-size: 20px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .child-box {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box span {
    margin-right: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box .third-box {
    font-size: 12px;
    margin-bottom: 2px;
    margin-left: 10px;
    font-weight: 500;
    cursor: pointer;
    color: #555;
  }
}
@media screen and (max-width: 400px) {
  .header-wrap .nav-coloapse {
    display: block;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .item-link {
    font-size: 16px;
    line-height: 22px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .first-level span {
    margin-right: 5px;
    font-size: 16px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .second-level span {
    margin-right: 5px;
    font-size: 16px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .child-box {
    margin-left: 10px;
    font-size: 12px;
  }
  .header-wrap .nav-coloapse .mobile-nav > li .child-box .son-box {
    margin-left: 10px;
    font-size: 12px;
  }
}
.white-bg {
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8;
}
.white-bg .pc-header .pc-header-nav .nav-item .item-link {
  color: #333;
}
.white-bg .pc-header .pc-header-right .lang-box > .iconfont,
.white-bg .pc-header .pc-header-right .shop-store > .iconfont,
.white-bg .pc-header .pc-header-right .lang-box > span,
.white-bg .pc-header .pc-header-right .shop-store > span {
  color: #333;
}
.white-bg .pc-header .pc-header-right .lang-box > .iconfont a,
.white-bg .pc-header .pc-header-right .shop-store > .iconfont a,
.white-bg .pc-header .pc-header-right .lang-box > span a,
.white-bg .pc-header .pc-header-right .shop-store > span a {
  color: #333;
}
.white-bg .pc-header .pc-header-right .lang-box > .name,
.white-bg .pc-header .pc-header-right .shop-store > .name {
  color: #333;
}
.white-bg .pc-header .pc-header-right .lang-box .ant-select-selection-item {
  color: #333 !important;
}
.white-bg .pc-header .pc-header-right .lang-box .ant-select-arrow {
  color: #333;
}
.white-bg .pc-header .pc-header-right .shop-store {
  border-color: #d8d8d8;
}
.head-bottom-line {
  border-bottom: 1px solid #e8e8e8;
}
.cookie-tips {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 8px;
  padding: 32px 40px;
  margin: 0px auto;
  z-index: 100000;
  font-size: 16px;
  color: #333333;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.cookie-tips .agree-text {
  cursor: pointer;
  background: #000;
  color: #fff;
  line-height: 32px;
  padding: 0 20px;
  border-radius: 16px;
  margin-left: 40px;
}
.cookie-tips .cookie-statement {
  color: #E70113;
  text-decoration: underline;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .cookie-tips {
    padding: 14px 19px;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    flex-direction: column;
  }
  .cookie-tips .agree-text {
    line-height: 28px;
    padding: 0 20px;
    margin-top: 6px;
    margin-left: 0;
    width: 100%;
    text-align: center;
  }
}
.ant-select-dropdown {
  z-index: 100 !important;
}
.ant-dropdown-menu {
  text-align: center !important;
}
.ant-dropdown-menu-item:hover {
  background: #e6f4ff !important;
}
.ant-popover-inner-content {
  padding: 27px 24px 32px;
}
.newsletter-popover .newsletter-name {
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}
.newsletter-popover .newsletter-content {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  margin-top: 4px;
  margin-bottom: 8px;
}
.newsletter-popover .email-box {
  display: flex;
}
.newsletter-popover .email-box .icon-btn {
  cursor: pointer;
  width: 46px;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  background-color: #E70113;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  vertical-align: bottom;
  border: none;
  padding: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.newsletter-popover .email-box .icon-btn > i.iconfont {
  font-size: 20px;
}
.newsletter-popover .ant-form-item {
  margin-bottom: 0;
  width: 305px;
}
.newsletter-popover .ant-input {
  max-width: 305px;
  padding: 10px 14px;
  height: 38px;
  font-size: 14px;
  border-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #DADADA;
}
.newsletter-popover .ant-input::placeholder {
  color: #C1C1C1;
}
.newsletter-popover .ant-form-item-explain.ant-form-item-explain-error {
  color: #E70113;
}
.newsletter-popover .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: #E70113;
}
.newsletter-popover .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  border-color: #E70113;
}
