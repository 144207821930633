// 分页样式重置
.product-page {
  margin-top: 48px;
  text-align: center;
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link  {
    border-radius: 50%;
  }
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-next {
    width: 40px;
    height: 40px;
    line-height: 38px;
    margin-right: 24px;
  }
  .ant-pagination-jump-next {
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #D8D8D8;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-options {
    display: none;
  }
  .ant-pagination-item-active a {
    background-color: #FF2C24;
    border-radius: 50%;
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    margin-top: 24px;
    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
      width: 30px;
      height: 30px;
      line-height: 28px;
      margin-right: 6px;
    }
  }
}
// 全局tab颜色重置
.ant-tabs {
  color: #333;
}
// 去掉输入框投影
.ant-input:focus,
.ant-select-focused.ant-select-multiple .ant-select-selector,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}
// 弹窗居中
.ant-modal{
  top: 50%;
  transform: translateY(-50%);
}
.common-video-modal .ant-modal{
  top: 100px;
  transform: none;
}