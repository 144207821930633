@import "./setting.less";
* {
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", "segoe ui", "PingFang SC", "Arial", "Microsoft Yahei",
    "Helvetica Neue", Helvetica, Tahoma, sans-serif !important;
}
html {
  font-family: "Open Sans" !important;
}
body,
ul,
li,
ol,
dl,
dd,
dt,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
img {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
fieldset,
img,
input,
button {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
} /*去掉input等聚焦时的蓝色边框*/
ul,
ol {
  list-style: none;
}
img {
  border: 0;
  vertical-align: middle;
  height: auto;
} /*  去掉图片低测默认的3像素空白缝隙，或者用display：block也可以*/
table {
  border-collapse: collapse;
}
body {
  color: #333;
  background-color: @body-bg;
}
a,
a:hover {
  color: #333;
  text-decoration: none;
  outline: none;
} /*去掉火狐虚线框*/
i,
em {
  font-style: normal;
}
figure {
  margin: 0;
}

::-webkit-input-placeholder {
  color: @placeholer-color;
}
:-moz-placeholder {
  color: @placeholer-color;
}
::-moz-placeholder {
  color: @placeholer-color;
}
:-ms-input-placeholder {
  color: @placeholer-color;
}
