.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}
.container {
  width: 1400px;
  margin: auto;
}
@media screen and (max-width: 1600px) {
  .container {
    width: 72%;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 86%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    width: 92%;
  }
}
.layout-container {
  padding-top: 80px;
  min-height: 600px;
  max-width: 1920px;
  margin: auto;
  background: #fff;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .layout-container {
    padding-top: 60px;
  }
}
.database-margin {
  margin-top: -60px;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/openSans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('../../assets/openSans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../../assets/openSans/OpenSans-Light.ttf') format('truetype');
}
.hover-content {
  display: block;
  width: max-content;
  height: 56px;
  border-radius: 60px;
  background: #E70113;
  position: fixed;
  bottom: 50px;
  right: 25px;
  z-index: 500;
  color: #fff;
  font-size: 14px;
}
.hover-content div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
}
.hover-content div .normal {
  width: 24px;
  height: 24px;
  background: url("../../static/connect_n.png") center center / cover no-repeat;
  margin-left: 18px;
  margin-right: 6px;
}
.hover-content div span {
  display: inline-block;
}
.hover-content:hover {
  box-shadow: 0px 4px 12px 0px rgba(231, 1, 19, 0.4);
}
.hover-content:hover .normal {
  background: url("../../static/connect_h.png") center center / cover no-repeat;
}
@media screen and (max-width: 768px) {
  .hover-content {
    width: 56px;
    height: 56px;
  }
  .hover-content div {
    justify-content: center;
    padding-right: 0;
  }
  .hover-content div .normal {
    margin: 0;
  }
}
.custom-btn-container-cls {
  position: fixed;
  bottom: 140px;
  right: 28px;
  z-index: 500;
}
.custom-btn-container-cls .custom-badge-cls {
  margin-bottom: 8px;
}
.custom-btn-container-cls .custom-badge-cls .ant-badge-count {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 22px;
  text-align: center;
  transform: translate(0, 0);
  padding: 0;
  border: 1px solid #fff;
  right: -3px !important;
  top: -9px !important;
}
.back-to-top {
  display: block;
  width: 56px;
  height: 56px;
  text-align: center;
  line-height: 56px;
  border-radius: 44px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.4);
  cursor: pointer;
}
.back-to-top .back-arrow {
  font-size: 24px;
}
.back-to-top .icon-gouwuche {
  font-size: 24px;
}
.back-to-top:hover {
  background: #E70113;
}
