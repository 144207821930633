.app_modal{
  .ant-modal-header{
    background: #F4F4F4;
    .ant-modal-title{
      font-weight: bold;
    }
  }
  .modal_confirm{
    padding: 60px 30px;
    text-align: center;
    font-size: 18px;
    @media screen and (max-width:767px){
        padding: 32px 16px;
        font-size: 16px;
    }
  }
  .login_again{
    margin-left: 8px;
  }
}
