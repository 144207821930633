* {
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", "segoe ui", "PingFang SC", "Arial", "Microsoft Yahei", "Helvetica Neue", Helvetica, Tahoma, sans-serif !important;
}
html {
  font-family: "Open Sans" !important;
}
body,
ul,
li,
ol,
dl,
dd,
dt,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
img {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
fieldset,
img,
input,
button {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}
/*去掉input等聚焦时的蓝色边框*/
ul,
ol {
  list-style: none;
}
img {
  border: 0;
  vertical-align: middle;
  height: auto;
}
/*  去掉图片低测默认的3像素空白缝隙，或者用display：block也可以*/
table {
  border-collapse: collapse;
}
body {
  color: #333;
  background-color: #fff;
}
a,
a:hover {
  color: #333;
  text-decoration: none;
  outline: none;
}
/*去掉火狐虚线框*/
i,
em {
  font-style: normal;
}
figure {
  margin: 0;
}
::-webkit-input-placeholder {
  color: #999;
}
:-moz-placeholder {
  color: #999;
}
::-moz-placeholder {
  color: #999;
}
:-ms-input-placeholder {
  color: #999;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}
.container {
  width: 1400px;
  margin: auto;
}
@media screen and (max-width: 1600px) {
  .container {
    width: 72%;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 86%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    width: 92%;
  }
}
.layout-container {
  padding-top: 80px;
  min-height: 600px;
  max-width: 1920px;
  margin: auto;
  background: #fff;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .layout-container {
    padding-top: 60px;
  }
}
.database-margin {
  margin-top: -60px;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../openSans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('../openSans/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../openSans/OpenSans-Light.ttf') format('truetype');
}
.product-page {
  margin-top: 48px;
  text-align: center;
}
.product-page .ant-pagination-item,
.product-page .ant-pagination-prev .ant-pagination-item-link,
.product-page .ant-pagination-next .ant-pagination-item-link {
  border-radius: 50%;
}
.product-page .ant-pagination-item,
.product-page .ant-pagination-prev,
.product-page .ant-pagination-next,
.product-page .ant-pagination-jump-next {
  width: 40px;
  height: 40px;
  line-height: 38px;
  margin-right: 24px;
}
.product-page .ant-pagination-jump-next {
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #D8D8D8;
}
.product-page .ant-pagination-prev,
.product-page .ant-pagination-next,
.product-page .ant-pagination-options {
  display: none;
}
.product-page .ant-pagination-item-active a {
  background-color: #FF2C24;
  border-radius: 50%;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .product-page {
    margin-top: 24px;
  }
  .product-page .ant-pagination-item,
  .product-page .ant-pagination-prev,
  .product-page .ant-pagination-next {
    width: 30px;
    height: 30px;
    line-height: 28px;
    margin-right: 6px;
  }
}
.ant-tabs {
  color: #333;
}
.ant-input:focus,
.ant-select-focused.ant-select-multiple .ant-select-selector,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}
.ant-modal {
  top: 50%;
  transform: translateY(-50%);
}
.common-video-modal .ant-modal {
  top: 100px;
  transform: none;
}
