// 单行省略
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// 清除浮动
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}

// 渐变动画
.transition(@time: 0.8s) {
  transition: all @time cubic-bezier(0.19, 1, 0.22, 1);
}

// 内部块宽度
.container {
  width: 1400px;
  margin: auto;
  @media screen and (max-width: 1600px) {
    width: 72%;
  }
  @media screen and (max-width: 1200px) {
    width: 86%;
  }
  @media screen and (max-width: 768px) {
    width: 92%;
  }
}

// 页面外框
.layout-container {
  padding-top: 80px;
  min-height: 600px;
  max-width: 1920px;
  margin: auto;
  background: #fff;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    padding-top: 60px;
  }
}

// 电子书模块去掉头部后magrin向上-60px
.database-margin {
  margin-top: -60px;
}
// 引入本地字体库
@font-face {
  font-family: 'Open Sans';
  src: url('../openSans/OpenSans-Regular.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('../openSans/OpenSans-Bold.ttf') format('truetype')
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../openSans/OpenSans-Light.ttf') format('truetype')
}